@import 'styles/layers.scss';

@layer uikitlevel1 {
  .popover {
    border-radius: 6px;
    width: 150px;
    :global(.popover-arrow) {
      transform: translate(66px, 0px) !important;
    }
  }

  .icon {
    margin-left: 6px;
  }
}
